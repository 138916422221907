<template>
  <a :href="link" target="_blank" class="border mb-4 p-4 flex justify-center">
    <div v-html="svg" class="qrcode"></div>
  </a>
</template>

<script>
import store from "@/store";
export default {
  props: {
    item_id: {
      type: String
    },
    title: {
      type: String
    }
  },
  data: () => ({
    image: null,
    emit: "image-selected"
  }),
  computed: {
    svg() {
      return _.unescape(this.image);
    },
    link() {
      return `${process.env.VUE_APP_API}/qrcode/${this.item_id}/500`;
    }
  },
  methods: {
    getQrCode(item_id) {
      store.dispatch("qr", item_id).then(res => {
        this.image = res;
      });
    }
  },
  watch: {
    item_id() {
      this.getQrCode(this.item_id);
    }
  }
};
</script>

<style scoped>
.qrcode >>> svg {
  width: 200px !important;
  height: 200px !important;
}
</style>
