<template>
  <div class="min-h-screen flex">
    <Sidebar />
    <div class="w-2/12"></div>
    <div class="p-4 w-10/12 flex flex-col is-relative">
      <b-loading
        :is-full-page="false"
        v-model="$store.getters.isProcessing"
      ></b-loading>
      <div class="flex items-center mb-4">
        <b-button
          class="mr-4"
          type="is-light"
          tag="router-link"
          :to="{ name: 'Items' }"
          >Back</b-button
        >
        <div class="is-size-4">{{ $route.name }}</div>
      </div>
      <div class="px-4 flex-grow flex">
        <div class="-mx-6 flex justify-center flex-wrap flex-grow">
          <div class="px-2 w-8/12">
            <div class="p-4 border">
              <b-field label="ID">
                <b-input
                  v-model="code"
                  disabled
                  placeholder="AUTOGENERATED"
                ></b-input>
              </b-field>
              <b-field
                label="Status"
                :type="$store.getters.hasErrors('stage_id').type"
                :message="$store.getters.hasErrors('stage_id').message"
              >
                <b-select placeholder="Status" v-model="stage_id" expanded>
                  <option
                    v-for="stage in stages"
                    :value="stage.id"
                    :key="stage.id"
                  >
                    {{ stage.name }}
                  </option>
                </b-select>
              </b-field>
              <b-field
                label="Remarks"
                :type="$store.getters.hasErrors('remarks').type"
                :message="$store.getters.hasErrors('remarks').message"
              >
                <b-input type="textarea" v-model="remarks"></b-input>
              </b-field>
              <b-field label="Product">
                <div class="flex mb-2">
                  <img
                    width="50"
                    :src="
                      product.image ? product.image.thumb : $default_image_thumb
                    "
                  />
                  <div class="ml-4">
                    {{ product.name }}
                    <br />
                    <small>
                      {{ product.code }}
                    </small>
                  </div>
                </div>
              </b-field>
              <b-field label="Project Name">
                <b-input v-model="project_name" disabled></b-input>
              </b-field>
              <b-field label="ETD">
                <b-datepicker
                  :date-formatter="toYmd"
                  v-model="etd"
                  show-week-number
                  placeholder="Click to select..."
                  icon="calendar-today"
                  trap-focus
                  disabled
                >
                </b-datepicker>
              </b-field>
              <b-field label="ETA">
                <b-datepicker
                  :date-formatter="toYmd"
                  v-model="eta"
                  show-week-number
                  placeholder="Click to select..."
                  icon="calendar-today"
                  trap-focus
                  disabled
                >
                </b-datepicker>
              </b-field>
            </div>
          </div>
          <div class="px-2 w-4/12">
            <QrCode title="QR Code" :item_id="code" />

            <div class="p-4 border">
              <div class="flex items-center justify-end">
                <b-button
                  type="is-success"
                  expanded
                  :disabled="$store.getters.isProcessing"
                  @click="onSave"
                  >Save changes</b-button
                >
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Sidebar from "@/components/admin/Sidebar";
import QrCode from "@/components/admin/QrCode";
import store from "@/store";
import { Events } from "@/events.js";
export default {
  components: {
    Sidebar,
    QrCode
  },
  data: () => ({
    code: "",
    stage_id: null,
    remarks: "",
    project_name: "",
    etd: null,
    eta: null,
    product: {},
    stages: []
  }),
  methods: {
    toYmd(date) {
      return moment(date).format("YYYY-MM-DD");
    },
    onSave() {
      store.dispatch("saveItemsForm", {
        id: this.$route.params.id || null,
        stage_id: this.stage_id,
        remarks: this.remarks
      });
    },
    setGalleryImageIds(ids) {
      this.image_ids = ids;
    }
  },
  beforeMount() {
    Events.$on("gallery-selected", this.setGalleryImageIds);
  },
  beforeRouteEnter(to, from, next) {
    if (to.meta.dispatch) {
      store
        .dispatch(to.meta.dispatch, { id: to.params.id, fr: to.query.id })
        .then(res => {
          next(vm => {
            vm.stages = res.data.stages;
            if (res.data.item) {
              vm.product = res.data.item.product;
              vm.project_name = res.data.item.joborder.project_name;
              vm.code = res.data.item.code;
              vm.stage_id = res.data.item.stage_id;
              vm.remarks = res.data.item.remarks;
              vm.etd = new Date(res.data.item.joborder.etd);
              vm.eta = new Date(res.data.item.joborder.eta);
            }
          });
        })
        .catch(() => {
          next({ name: "Items" });
        });
    }
  }
};
</script>
